.container {
    /* width: 700px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0;
}

@media screen and (min-width: 1024px){
    .container{
      width: 700px;
    }
}

.coverImg {
    background: url('../divide.jpg');
    background-attachment: fixed;
    background-position: 50%;        
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center; 
    display: flex;   
}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #FFF;    
    padding: 0px 10px 5px 10px;
}

.content {
    color: #555;    
    line-height: 27px;
}

.uniformContainer {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0px
}