div.separatorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 5px 0;
}

div.separator {
    border-bottom: 2px solid red;
    width: 100px;    
}
