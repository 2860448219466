.container {
    background: url('./office.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100vh;    
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    font-size: 36px;
}