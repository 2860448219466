.container {
    /* max-width: 700px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0;
}

@media screen and (min-width: 1024px){
    .container{
      width: 700px;
    }
}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #444;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0px 0px 5px 0px
}

.content {
    color: #555;
    line-height: 27px;
    padding: 20px;
}