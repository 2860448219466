.container {
    display: flex;
    flex-direction: column;   
    padding: 40px 0; 
}

.title {
    font-size: 25px;
    font-weight: bold;
    color: #444;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0px 0px 5px 0px;        
}

.subtitle {
    font-size: 20px;
    font-weight: bold;
    color: #444;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    padding: 10px 10px 10px 10px;        
}

.serviceContainer {
    display: flex;
    flex-wrap: wrap;    
    justify-content: space-around;
    max-width: 1000px;
    margin-bottom: 10px;
}

.serviceItem {
    width: 300px;
    height: 325px;    
    margin-top: 10px;
    display: flex;
    justify-content: center;    
    flex-direction: column;    
    padding: 0 10px;
}

.serviceItem .photo {    
    height: 200px;
}

.serviceItem .photo img{    
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.serviceItem .caption {    
    height: 125px;
    background: rgb(233, 232, 232);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid rgb(173, 173, 173);
    padding: 10px;
    line-height: 23px;
    color: #555;
}
