.title {
    font-size: 25px;
    font-weight: bold;
    color: #FFF;    
    padding: 0px 10px 5px 10px;
}

.coverImg {
    background: url('../divide.jpg');
    background-attachment: fixed;
    background-position: 50%;        
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center; 
    display: flex;   
}

.contactInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.contactInfoLeft {
    line-height: 25px;
    padding: 10px;    
    flex: 1;
}

.contactInfoLeft div span:nth-child(1) {
    display: inline-block;
    width: 80px;
    text-align: right;
    padding-right: 10px;
    flex-shrink: 0;
}

.row {
    display: flex;
}

.mapContainer {
    
}

.map {
    width: 100%;
    height: 300px;
    border: 0;
}

.row a {
    color: blue;
}

.row a:visited {
    color: purple;
    text-decoration: underline;
}