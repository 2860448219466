.container {    
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;    
}

.container[hide="true"] {    
    animation: hideHeader ease-in 0.2s 1;
    animation-fill-mode: forwards;    
}

.container a {
    text-decoration: none;
    color: #555;
}

.container a:visited {
    text-decoration: none;
    color: #555;
}

.container[hide="true"] a {    
    color: #FFF
}


@keyframes hideHeader {
    0%     { background-color: rgba(255, 255, 255, 1); }
    100%   { background-color: rgba(0, 0, 0, 0.3); }
}

@keyframes showHeader {
    0%     { background-color: rgba(0, 0, 0, 0.3); }
    100%   { background-color: rgba(255, 255, 255, 1); }
}

.links span {
    padding: 0 5px;
}

.logo img {
    height: 50px
}

.content {
    display: flex;
    align-items: center;
    padding: 5px;
}

.links {
    padding-left: 10px;
    margin-left: auto;
    margin-right: 10px;
    display: block;
}

.menuBtn {
    display: none;
    cursor: pointer;
}

.menuOverlay {
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0);    
    position: fixed;
    top: 0;
    left: 0;    
    z-index: 1;
}

.menu {
    height: 100vh;
    width: 35vw;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    position: fixed;
    top: 0;
    right: 0;    
    z-index: 10;
}

@media screen and (max-width: 400px) {
    .menu {
        width: 50vw;
    }
}

.menuOverlay[hide='true'] {
    animation: hideOverlay ease-in 0s 1;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;    
}

.menuOverlay[hide='false'] {
    animation: showOverlay ease-in 0s 1;
    animation-fill-mode: forwards;
}

.menu[hide='true'] {
    animation: hideMenu ease-in 0.2s 1;
    animation-fill-mode: forwards;    
}

.menu[hide='false'] {
    animation: showMenu ease-in 0.2s 1;
    animation-fill-mode: forwards;
}

@keyframes hideMenu {
    0%     { transform: translate3d(0, 0, 0); opacity: 1;}
    100%   { transform: translate3d(100%, 0, 0); opacity: 0;}
}

@keyframes showMenu {
    0%     { transform: translate3d(100%, 0, 0); opacity: 0;}
    100%   { transform: translate3d(0, 0, 0); opacity: 1;}
}

@keyframes hideOverlay {
    0%     { transform: translate3d(0, 0, 0); }
    100%   { transform: translate3d(100%, 0, 0); }
}

@keyframes showOverlay {
    0%     { transform: translate3d(100%, 0, 0); }
    100%   { transform: translate3d(0, 0, 0); }
}

@media screen and (max-width: 800px) {
    .menuBtn {
        display: block;
        margin-left: auto;
    }

    .links {
        display: none;
    }
}