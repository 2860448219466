.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0
}

.menuItemContainer a, .menuItemContainer a:visited, .menuItemContainer a:hover{
    text-decoration: none;
    color: #000;
}

.menuItem {
    text-align: center;
    padding: 5px 0;
}